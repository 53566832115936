import React, { useState } from "react";
import "./Header.css";

import logo from "../../assets/logo/CropNow_logo.png";
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="navbar">
        <div className="navbar-left">
          <div className="menu-icon" onClick={toggleMenu}>
            ☰
          </div>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
        </div>

        <div className={`navbar-right ${menuOpen ? "open" : ""}`}>
          <nav className="nav-links">
            <a href="#home">Home</a>
            <a href="#about">About</a>
            <a href="#career">Career</a>
            <a href="#products">Products</a>
          </nav>
          <div className="login-register">
            <button>Login / Register</button>
          </div>
        </div>
      </div>

      {menuOpen && (
        <div className="dropdown-menu">
          <a href="#home" onClick={toggleMenu}>
            Home
          </a>
          <a href="#about" onClick={toggleMenu}>
            About
          </a>
          <a href="#career" onClick={toggleMenu}>
            Career
          </a>
          <a href="#products" onClick={toggleMenu}>
            Products
          </a>
          <button onClick={toggleMenu}>Login / Register</button>
        </div>
      )}
    </header>
  );
};

export default Header;
