import React from "react";
import backgroundImage from "../../assets/HeroImages/img2.jpg";
import productImage from "../../assets/HeroImages/img2.jpg";
// import productImage from "../../assets/ProductImages/product.jpg"; // Adjust the path as per your structure
import productImage1 from "../../assets/images/seedrover.png";
import productImage2 from "../../assets/images/nest.png";
import productImage3 from "../../assets/images/waste_management.png";
import "./Hero4.css"; // Import the custom CSS for Hero4

const Hero4 = () => {
  return (
    <div
      className="hero4-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay">
        <div className="content">
          <h1>Crop Now's Upcoming Products</h1>

          <div className="community-boxes">
            <div className="box">
              <img
                src={productImage1}
                alt="Product"
                className="product-image"
              />
              <h2>SEED</h2>
              <p>
                [ SMART ECO-FARMING & ENVIRONMENTAL DEVICE ] SEED is an
                automated rover designed for smart eco-farming and environmental
                monitoring.
              </p>
            </div>
            <div className="box">
              <img
                src={productImage3}
                alt="Product"
                className="product-image"
              />
              <h2>
                Waste Management Machine <br />
                <span>Portable and Solar-Based</span>
              </h2>

              <p>
                Our waste management machine is designed to efficiently process
                organic waste into valuable products while being highly portable
                and environmentally friendly.
              </p>
            </div>

            <div className="box">
              <img
                src={productImage2}
                alt="Product"
                className="product-image"
              />
              <h2>NEST</h2>
              <p>
                [Nurturing Environmental Soil Technology] NEST is a stationary
                soil health station designed to monitor and analyze soil
                properties, providing crucial data on moisture, temperature, pH
                levels, and nutrient content.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero4;
