// import { CircularProgress } from "@mui/material";
import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

// const Footer = lazy(() => import("../Footer/Footer"));

const Layout = ({ children }) => {
  return (
    <div>
      {/* <Navbar /> */}
      <Header />
      <div className="--pad" style={{ minHeight: "80vh" }}>
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
