import React, { useState, useEffect, useRef } from "react";
import Hero1 from "../../Container/Hero1/Hero1";
import Hero2 from "../../Container/Hero2/Hero2";
import Hero3 from "../../Container/Hero3/Hero3";
import Hero4 from "../../Container/Hero4/Hero4";
import "./Home.css";

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [<Hero1 />, <Hero2 />, <Hero3 />, <Hero4 />];
  const totalSlides = slides.length;
  const slideIntervalRef = useRef(null);

  useEffect(() => {
    startSlideShow();
    return () => clearInterval(slideIntervalRef.current); // Cleanup on unmount
  }, []);

  const startSlideShow = () => {
    slideIntervalRef.current = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % totalSlides);
    }, 8000); // 8000ms = 8 seconds
  };

  const stopSlideShow = () => {
    clearInterval(slideIntervalRef.current);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  return (
    <div
      className="slider"
      onMouseEnter={stopSlideShow}
      onMouseLeave={startSlideShow}
    >
      <div
        className="slides"
        style={{ transform: `translateX(-${currentSlide * 100}vw)` }}
      >
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            {slide}
          </div>
        ))}
      </div>
      <button className="prev" onClick={handlePrevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={handleNextSlide}>
        &#10095;
      </button>
      <div className="aibutton">
        <div className="aibutton-text">
          <p>Crop Anna Coming Soon</p>
          <button></button>
        </div>
      </div>
    </div>
  );
};

export default Home;
