import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Container } from "@mui/material";
import "./App.css";

// const Home = lazy(() => import("./Pages/Home/Home"));
import Home from "./Pages/Home/Home";
import Layout from "./Container/Layout/Layout";

const App = () => {
  return (
    <div className="app">
      <div className="routes">
        <ToastContainer />
        <Suspense
          fallback={
            <Container>
              <CircularProgress />
            </Container>
          }
        >
          <Routes>
            <Route
              path="/"
              exact
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            ></Route>
          </Routes>
        </Suspense>
      </div>
    </div>
  );
};

export default App;
